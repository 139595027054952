import React from "react";
import { tutorials } from "../data/Tutorials";


const form: string = `

<div class="ff-614d4d9dbc3eea86ee42ee07" data-ff-el="root" data-ff-version="3" data-ff-type="inline" data-ff-name="ribbonBanner">
  <!--tpl {% block config %} tpl-->
  <div data-ff-el="config" data-ff-config="eyJ0cmlnZ2VyIjp7Im1vZGUiOiJpbW1lZGlhdGVseSIsInZhbHVlIjpudWxsfSwib25TdWNjZXNzIjp7Im1vZGUiOiJyZWRpcmVjdCIsIm1lc3NhZ2UiOiJUeXBlIHlvdXIgY3VzdG9tIHN1Y2Nlc3MgbWVzc2FnZSBoZXJlLi4uIiwicmVkaXJlY3RVcmwiOiJodHRwczovL3d3dy5saW5rcmVkaXJlY3QuaW8vY3JlYXRlIn0sImNvaSI6ZmFsc2UsInNob3dGb3JSZXR1cm5WaXNpdG9ycyI6dHJ1ZSwibm90aWZpY2F0aW9uIjp0cnVlfQ==" style="display: none"></div>
  <!--tpl {% endblock %} tpl-->
  <div class="ff-614d4d9dbc3eea86ee42ee07__container">
    <form class="ff-614d4d9dbc3eea86ee42ee07__form" action="https://form.flodesk.com/forms/614d4d9dbc3eea86ee42ee07/submit" method="post" data-ff-el="form">
      <div class="ff-614d4d9dbc3eea86ee42ee07__title">
        <div></div>
      </div>
      <div class="ff-614d4d9dbc3eea86ee42ee07__subtitle">
        <div></div>
      </div>
      <div class="ff-614d4d9dbc3eea86ee42ee07__content fd-form-content" data-ff-el="content">
        <div class="ff-614d4d9dbc3eea86ee42ee07__fields" data-ff-el="fields">
          <!--tpl {% block fields %} tpl-->

          <div class="ff-614d4d9dbc3eea86ee42ee07__field fd-form-group">
          <label class="ff-614d4d9dbc3eea86ee42ee07__label fd-form-label"><strong>Email address</strong></label>
            <input class="ff-614d4d9dbc3eea86ee42ee07__control fd-form-control" type="text" name="email" placeholder="Email address" data-ff-tab="email::firstName" required />
          </div>


          <div class="ff-614d4d9dbc3eea86ee42ee07__field fd-form-group">
          <label class="ff-614d4d9dbc3eea86ee42ee07__label fd-form-label"><strong>First name</strong></label>
            <input class="ff-614d4d9dbc3eea86ee42ee07__control fd-form-control" type="text" name="firstName" placeholder="First name" data-ff-tab="firstName:email:option2" required />
          </div>

          <input type="text" name="confirm_email_address" style="display: none" />
          <!--tpl {% endblock %} tpl-->
        </div>

        <div class="ff-614d4d9dbc3eea86ee42ee07__preference">
          <div class="ff-614d4d9dbc3eea86ee42ee07__preference-title">
            <div>
              <p>By checking the box below, you consent to receive email newsletters from Eran Bucai, founder of DotComTruths.com</p>
            </div>
          </div>
          <div class="ff-614d4d9dbc3eea86ee42ee07__preference-control">
            <div class="ff-614d4d9dbc3eea86ee42ee07__preference-list">
              <!--tpl {% block preferences %} tpl-->

              <div class="ff-614d4d9dbc3eea86ee42ee07__preference-item">
                <label class="ff-614d4d9dbc3eea86ee42ee07__form-check fd-form-check">
                  <input required type="checkbox" name="preferences" class="fd-form-check__input" value="option2" data-ff-tab="option2:firstName:submit" />
                  <span class="fd-form-check__checkmark"></span>
                  <span class="fd-form-check__label"><strong>Yes, I consent to receiving emails.</strong></span>
                </label>
              </div>

              <!--tpl {% endblock %} tpl-->
            </div>
          </div>
        </div>
        <div class="ff-614d4d9dbc3eea86ee42ee07__footer" data-ff-el="footer">
          <button type="submit" class="ff-614d4d9dbc3eea86ee42ee07__button fd-btn" data-ff-el="submit" data-ff-tab="submit">
            <span>Get free access</span>
          </button>
        </div>
      </div>
      <div class="ff-614d4d9dbc3eea86ee42ee07__success fd-form-success" data-ff-el="success">
        Thanks! You're subscribed. The freebie is on the way to your inbox… make sure you check spam/trash just in case…
      </div>
      <div class="ff-614d4d9dbc3eea86ee42ee07__error fd-form-error" data-ff-el="error"></div>
    </form>
  </div>
</div>
<script>
  (function(w, d, t, h, s, n) {
    w.FlodeskObject = n;
    var fn = function() {
      (w[n].q = w[n].q || []).push(arguments);
    };
    w[n] = w[n] || fn;
    var f = d.getElementsByTagName(t)[0];
    var v = '?v=' + Math.floor(new Date().getTime() / (120 * 1000)) * 60;
    var sm = d.createElement(t);
    sm.async = true;
    sm.type = 'module';
    sm.src = h + s + '.mjs' + v;
    f.parentNode.insertBefore(sm, f);
    var sn = d.createElement(t);
    sn.async = true;
    sn.noModule = true;
    sn.src = h + s + '.js' + v;
    f.parentNode.insertBefore(sn, f);
  })(window, document, 'script', 'https://assets.flodesk.com', '/universal', 'fd');
</script>
<script>
  window.fd('form:handle', {
    formId: '614d4d9dbc3eea86ee42ee07',
    rootEl: '.ff-614d4d9dbc3eea86ee42ee07',
  });
</script>

`;

export default function Home() {
  return (
    <div>
      <div className="welcome-video">
        <div className="videoWrapper">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/NsXNBCekM8w"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <div className="works-with">
          <p>Works great with</p>
          
          <div className="logos">
              {tutorials.map((tutorial, key) => (
                <img
                  src={`/images/${tutorial.software}@512x.png`}
                  alt={tutorial.software}
                  key={key}
                />
              ))}
            </div>
      </div>
      <div className="form">
          <h2>Get free access</h2>
        <div dangerouslySetInnerHTML={{ __html: form }}></div>
      </div>
    </div>
  );
}
