import React from "react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
import Home from "./pages/Home";
import Create from "./pages/Create";

function App() {
  return (
    <Router>
      <div className="App">
        <header>
          <div className="wrap">
            <h1 className="logo">
              LinkRedirect.io <em>Code generator</em>
            </h1>
          </div>
        </header>
        <div className="content">
          <div className="wrap">
            <Switch>
              <Route path="/create">
                <Create />
              </Route>
              <Route path="/" exact={true}>
                <Home />
              </Route>
              <Route path="*">
                <Redirect to="/" />
              </Route>
            </Switch>
          </div>
        </div>
        <footer>
          <div className="wrap">
            <div className="inside">
              <p>
                Powered by{" "}
                <a
                  href="https://DotComTruths.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  DotComTruths.com
                </a>
              </p>
            </div>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;
